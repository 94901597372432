import { React, useState } from "react"
import { useForm } from "react-hook-form"
import { FaExternalLinkAlt } from "react-icons/fa"

/** @jsx jsx */
import {
  jsx,
  Alert,
  Box,
  Heading,
  Text,
  Styled,
  Button,
  Flex,
  Label,
  Input,
  Select,
  Textarea,
  Checkbox,
  Link as ThemeUILink,
} from "theme-ui"

import * as content from "../content/order.js"
import { formsBackendEndpoint } from "../config"

const encode = data => {
  content.productData.map(product => {
    if (data[product.fieldName] === "") {
      data[product.fieldName] = 0
    }
  })
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const OrderForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [success, setSuccess] = useState(false)

  const onSubmit = async (data, e) => {
    e.preventDefault()
    await fetch(`https://${formsBackendEndpoint}/orderforms`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ ...data }),
    })
      .then(async response => {
        console.log(response)
        if (response.status != 200) {
          await response.json().then(data => {
            console.log(data)
          })
          throw "Da ist leider etwas schief gelaufen. Schreiben Sie uns bitte eine E-Mail..."
        } else {
          setSuccess(true)
        }
      })
      .catch(error => alert(error))
  }

  const warning = "⚠ Eingabe erforderlich."

  if (!success) {
    return (
      <Box>
        <Heading as="h1" variant="siteTitle">
          Bestellformular
        </Heading>
        <Box as="form" name="orders" onSubmit={handleSubmit(onSubmit)}>
          <Heading as="h2">Versandinformationen</Heading>
          <Box sx={{ display: ["block", "flex"] }}>
            <Box sx={{ flex: ["30%", "50%", "30%"], px: 3 }}>
              <Label htmlFor="anrede">Anrede</Label>
              <Select
                name="anrede"
                id="anrede"
                mb={3}
                ref={register}
                defaultValue="default"
              >
                <option value="default" disabled>
                  Bitte wählen...
                </option>
                <option>Frau</option>
                <option>Herr</option>
              </Select>
            </Box>
            <Box sx={{ flex: ["100%", "50%", "30%"], px: 3 }}>
              <Label htmlFor="organisation">Firma/Organisation</Label>
              <Input
                name="organisation"
                id="organisation"
                mb={3}
                ref={register}
              />
            </Box>
          </Box>
          <Box sx={{ display: ["block", "flex"] }}>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="vorname">Vorname *</Label>
              <Input
                name="vorname"
                id="vorname"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.vorname && 0 }}
              />
              {errors.vorname && <Alert variant="form">{warning}</Alert>}
            </Box>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="nachname">Nachname *</Label>
              <Input
                name="nachname"
                id="nachname"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.nachname && 0 }}
              />
              {errors.nachname && <Alert variant="form">{warning}</Alert>}
            </Box>
          </Box>
          <Box sx={{ display: ["block", "flex"] }}>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="street">Straße/Hausnummer *</Label>
              <Input
                name="street"
                id="street"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.street && 0 }}
              />
              {errors.street && <Alert variant="form">{warning}</Alert>}
            </Box>
            <Box sx={{ flex: "15%", px: 3 }}>
              <Label htmlFor="postalCode">PLZ *</Label>
              <Input
                name="postalCode"
                id="postalCode"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.postalCode && 0 }}
              />
              {errors.postalCode && <Alert variant="form">{warning}</Alert>}
            </Box>
            <Box sx={{ flex: "35%", px: 3 }}>
              <Label htmlFor="city">Ort *</Label>
              <Input
                name="city"
                id="city"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.city && 0 }}
              />
              {errors.city && <Alert variant="form">{warning}</Alert>}
            </Box>
          </Box>
          <Box sx={{ display: ["block", "flex"] }}>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="country">Land</Label>
              <Input name="country" id="country" mb={3} ref={register} />
            </Box>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="phone">Telefon</Label>
              <Input name="phone" id="phone" mb={3} ref={register} />
            </Box>
          </Box>
          <Box sx={{ display: ["block", "flex"] }}>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="fax">Fax</Label>
              <Input name="fax" id="fax" mb={3} ref={register} />
            </Box>
            <Box sx={{ flex: "50%", px: 3 }}>
              <Label htmlFor="eMail">E-Mail *</Label>
              <Input
                name="eMail"
                id="eMail"
                mb={3}
                ref={register({ required: true })}
                sx={{ mb: errors.eMail && 0 }}
                type="email"
              />
              {errors.eMail && <Alert variant="form">{warning}</Alert>}
            </Box>
          </Box>
          <Heading as="h2" sx={{ pt: 3, pb: 4 }}>
            Bücher / Non-Books
          </Heading>
          {content.productData.map(product => (
            <Flex sx={{ px: 3 }}>
              <Input
                name={product.fieldName}
                id={product.fieldName}
                mb={3}
                ref={register}
                sx={{ width: "60px", height: "30px", textAlign: "center" }}
              />
              <Label htmlFor={product.fieldName} sx={{ ml: 3, mb: 2 }}>
                {product.link ? (
                  <Box>
                    <Styled.a
                      variant="default"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${product.link}`}
                    >
                      {product.label}
                    </Styled.a>{" "}
                    <FaExternalLinkAlt sx={{ fontSize: 14 }} />
                  </Box>
                ) : (
                  product.label
                )}
              </Label>
            </Flex>
          ))}
          <Heading as="h2" sx={{ pt: 3 }}>
            Kosten-Information
          </Heading>
          <Box sx={{ px: 3 }}>
            <Text variant="default">
              Gerne schicken wir Ihnen das gewünschte Produkt (Buch, Non-Book)
              gegen Rechnung kostenpflichtig zu.
            </Text>
            <Text variant="default">
              <span sx={{ fontWeight: 700 }}>Versandkosten: Inland</span>
              <br />
              Bücher: keine Versandkosten
              <br />
              Frühstücksbrettchen und Handtuch: 3,30 Euro
            </Text>
            <Text variant="default">
              <span sx={{ fontWeight: 700 }}>Versandkosten: Ausland</span>
              <br />
              Bücher, Frühstücksbrettchen und Handtuch: 7,00 Euro je Produkt bei
              Bestellungen innerhalb Europas; bei Bestellung von mehreren
              Produkten richten sich die Versandkosten nach dem Gewicht. Bitte
              erfragen Sie diese vorab bei uns. Für den Versand der
              Gesamtedition »Heute schon geschrieben?« (2 Bände) berechnen wir
              12,00 Euro Versandkosten innerhalb der EU, außerhalb der EU (inkl.
              Schweiz) sind es 17,00 Euro.
              <br />
              Rabatt für den Buchhandel: 30 Prozent
            </Text>
            <Text variant="default" sx={{ fontWeight: 700 }}>
              Hinweis auf EU-Streitschlichtungsplattform
            </Text>
            <Text variant="default">
              Plattform der EU-Kommission zur Online-Streitbeilegung:{" "}
              <Styled.a
                href="http://ec.europa.eu/consumers/odr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/consumers/odr/
              </Styled.a>{" "}
              <FaExternalLinkAlt sx={{ fontSize: 14 }} />
            </Text>
            <Text variant="default">
              Unsere E-Mail-Adresse lautet:{" "}
              <Styled.a href="mailto:info@uschtrin.de">
                info@uschtrin.de
              </Styled.a>
            </Text>
          </Box>
          <Heading as="h2" sx={{ pt: 4 }}>
            Sonstiges
          </Heading>
          <Box sx={{ px: 3 }}>
            <Label htmlFor="message">
              Zusätzliche Informationen (z.B.abweichende Rechnungsadresse o.ä.)
            </Label>
            <Textarea
              name="message"
              id="message"
              rows="6"
              mb={3}
              ref={register}
            />
          </Box>
          <Box>
            <Box sx={{ px: 3 }}>
              <Label htmlFor="privacy" mb={3}>
                <Checkbox
                  name="privacy"
                  id="privacy"
                  ref={register({ required: true })}
                  sx={{ mt: 1, color: "text" }}
                />
                <Text sx={{ pl: 4 }}>
                  Hiermit willige ich ein, dass meine in diesem Bestellformular
                  angegeben Daten gemäß der Datenschutzerklärung erfasst,
                  übermittelt und gespeichert werden. *
                </Text>
              </Label>
              {errors.privacy && (
                <Alert variant="form">⚠ Einwilligung erforderlich.</Alert>
              )}
              <Button>Zahlungspflichtig bestellen</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box>
        <Heading variant="subheading" sx={{ fontStyle: "unset" }}>
          Vielen Dank für Ihre Bestellung!
          <br />
          <br />
          Wir werden diese umgehend bearbeiten und versenden. Für Rückfragen
          wenden Sie sich bitte an{" "}
          <Styled.a href="mailto:service@uschtrin.de">
            service@uschtrin.de
          </Styled.a>
          .
          <br />
          <br />
          Herzliche Grüße
        </Heading>
        <Text variant="default" sx={{ fontStyle: "italic", fontWeight: 700 }}>
          Uschtrin Verlag
        </Text>
      </Box>
    )
  }
}

export default OrderForm
