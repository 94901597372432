import React from "react"

import Layout from "../components/layout"
import OrderForm from "../components/orderForm"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Heading } from "theme-ui"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Bestellformular"
      description="Bestellen Sie hier Ihre Bücher beim Uschtrin Verlag."
    />
    <OrderForm />
  </Layout>
)

export default Page
