export const productData = [
  
  {
    fieldName: "brettNormseiteAmount",
    label: "Frühstücksbrettchen »Die Normseite« (je 6,90 EUR)",
  },
  {
    fieldName: "brettExposeAmount",
    label: "Frühstücksbrettchen »Das Exposé« (je 6,90 EUR)",
  },
  {
    fieldName: "brettBuchstabensuppeAmount",
    label: "Frühstücksbrettchen »Buchstabensuppe« (je 6,90 EUR)",
  },
  {
    fieldName: "brettBuchstabensalatAmount",
    label: "Frühstücksbrettchen »Buchstabensalat« (je 6,90 EUR)",
  },
]
